<template>
    <v-card outlined class="borderTable">
        <v-card-title class="secondaryMain">
            <h5 class="lightenPrimary--text">{{ title }}</h5>
            <v-spacer />
            <v-text-field dark v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line hide-details />
        </v-card-title>
        <v-card-text class="white pa-2">
            <v-data-table
                multi-sort
                dense
                :search="search"
                :headers="headers"
                :loading="loadingReservas"
                :items="_lista"
                item-key="_id"
                caption
                class="elevation-1"
                :items-per-page="15"
            >
                <template v-slot:[`item._id`]="{ item }">
                    <v-row class="d-flex flex-column align-center ma-2">
                        <v-chip :color="item.finalizado === 1 ? 'red' : 'green'" dark> {{ item._id }} </v-chip>
                        <b v-if="item.idOrdenCompra" class="bold green--text">OC_{{ item.idOrdenCompra }}</b>
                        <b v-if="item.numeroComprobante" class="bold green--text">{{ item.numeroComprobante }}</b>
                    </v-row>
                </template>
                <template v-slot:[`item.descEstado`]="{ item }" v-if="[2, 4].includes(user['idBussines'])">
                    <v-chip v-if="item.descEstado" :color="colorEstado(item.idEstado)" dark> {{ item.descEstado }} </v-chip>
                </template>
                <template v-slot:[`item.UserAssignment`]="{ item }">
                    <v-row class="d-flex flex-column align-center ma-2">
                        <v-chip v-if="item.UserAssignment"> {{ item.UserAssignment }} </v-chip>
                        <b v-if="item.web_online"> {{ item.web_online }} </b>
                    </v-row>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="orange lighten-2" small @click="createOrden(item)">mdi-factory</v-icon>
                        </template>
                        <span>Orden de Pedido</span>
                    </v-tooltip>
                    <div v-if="item.estado != 1">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" @click="editItem(item)">
                                    <v-icon small color="green lighten-2">mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <!-- <v-tooltip bottom v-if="optionalRole.isAdmin">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" @click="reAssignment(item)">
                                    <v-icon small color="green lighten-2">mdi-reload</v-icon>
                                </v-btn>
                            </template>
                            <span>Asignar</span>
                        </v-tooltip> -->

                        <v-tooltip bottom v-if="optionalRole.isAdmin">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-if="item.finalizado != 1" v-bind="attrs" v-on="on" @click="openDelete(item)">
                                    <v-icon color="primary" small>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>

                        <!-- <v-tooltip bottom v-if="optionalRole.isAdmin && user.idBussines == 1"> -->
                    </div>
                    <div v-else>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" @click="showItem(item)">
                                    <v-icon color="blue lighten-2" small>mdi-file-find</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" @click="printReservation(item)">
                                    <v-icon color="green lighten-2" small>mdi-cloud-print</v-icon>
                                </v-btn>
                            </template>
                            <span>Imprimir</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="optionalRole.isAdmin || [2, 3].includes(user.id_role)">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" @click="activeTicket(item)">
                                    <v-icon color=" blue " small>mdi-lightbulb-on</v-icon>
                                </v-btn>
                            </template>
                            <span>Activar</span>
                        </v-tooltip>
                    </div>
                    <v-tooltip bottom v-if="!item.numeroComprobante">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-if="item.finalizado != 1" v-bind="attrs" v-on="on" @click="openDialog3(item)">
                                <v-icon color="blue" small>mdi-file-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Generar Comprobante</span>
                    </v-tooltip>
                    <!-- <v-tooltip bottom v-if="optionalRole.isAdmin && user.idBussines == 1"> -->
                    <v-tooltip bottom v-if="item.atributos?.url_pdf_doc">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on" :href="item.atributos?.url_pdf_doc">
                                <v-icon color="blue" small>mdi-file-document-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>COMPROBANTE PDF</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.atributos?.urlGuiaRemision">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on" :href="item.atributos?.urlGuiaRemision">
                                <v-icon color="blue" small>mdi-file-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>GUIA REMISION</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card-text>
        <v-dialog v-model="dialog2" max-width="300px">
            <v-card>
                <v-card-title class="grey lighten-3">
                    <h5>¿Desea eliminar el ticket {{ itemSelected.id }}?</h5>
                </v-card-title>
                <v-card-actions>
                    <v-btn color="primary" text @click="dialog2 = false">atras</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="loading2" @click="deleteItem">ELIMINAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialog3" max-width="420px">
            <form @submit.prevent="comprobante">
                <v-card>
                    <v-card-title class="grey lighten-3">
                        <v-col cols="12">
                            <h6>
                                ¿Desea proceder con la emision de la {{ itemSelected.idTipoComprobante === 1 ? 'FACTURA' : 'BOLETA' }} <br />
                                a
                                {{ itemSelected.full_name }} ?
                            </h6>
                        </v-col>
                    </v-card-title>
                    <v-card-text>
                        <div class="primary text-center ma-4" color="primary">
                            <span class="white--text"
                                >TOTAL A PAGAR:&nbsp;&nbsp;
                                {{
                                    itemSelected.montoFacturacion
                                        ? `${itemSelected.idTipoMoneda == 1 ? 'S/.' : '$'} ${itemSelected.montoFacturacion}`
                                        : itemSelected.importeTotal
                                }}
                            </span>
                        </div>
                        <v-row dense class="pt-4 ma-0">
                            <v-col cols="12">
                                <v-autocomplete
                                    outlined
                                    dense
                                    v-model="itemSelected.idTipoComprobante"
                                    v-validate="'required'"
                                    :items="tipoComprobanteFact"
                                    item-text="descripcion"
                                    placeholder="Tipo Comprobante"
                                    item-value="id"
                                    label="Tipo Comprobante"
                                    data-vv-name="itemSelected.idTipoComprobante"
                                    data-vv-as="Tipo"
                                    required
                                />
                            </v-col>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-autocomplete
                                        class="required"
                                        outlined
                                        dense
                                        v-model="itemSelected.idCondicionPago"
                                        item-text="value"
                                        item-value="id"
                                        :items="condicionPago"
                                        label="Condicion de pago"
                                        v-validate="'required'"
                                        required
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="ma-auto" v-if="itemSelected.idCondicionPago == 2">
                                <v-row v-for="(item, index) in cuotas" :key="index">
                                    <v-col cols="5" sm="5">
                                        <v-menu v-model="item.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    v-model="item.date"
                                                    label="Fecha de Pago"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                />
                                            </template>
                                            <v-date-picker v-model="item.date" locale="es" @input="item.menu = false" />
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="5" sm="5">
                                        <v-text-field
                                            dense
                                            outlined
                                            v-validate="'required'"
                                            v-model="item.importe"
                                            class="required"
                                            required
                                            label="Saldo Recibido"
                                            @keypress="isNumber"
                                        />
                                    </v-col>
                                    <v-col cols="2" sm="2" class="pt-6">
                                        <v-row v-if="!(index == 0)">
                                            <v-btn icon large @click="deleteCuota(index)">
                                                <v-icon color="primary" small>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-col cols="12" sm="2">
                                    <v-btn icon color="indigo" @click="pushCuotas()">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-col cols="12" v-if="[2, 4].includes(this.user.idBussines)">
                                <v-autocomplete
                                    outlined
                                    dense
                                    v-model="itemSelected.idUserAssignment"
                                    v-validate="'required'"
                                    :items="listUserSede"
                                    item-text="nombres"
                                    placeholder="Vendedor"
                                    item-value="id"
                                    label="Vendedor"
                                    data-vv-name="itemSelected.idUserAssignment"
                                    data-vv-as="Vendedor"
                                    required
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3" sm="12">
                                <v-autocomplete
                                    outlined
                                    dense
                                    clas="required"
                                    v-model="itemSelected.isEmailSelected"
                                    item-text="value"
                                    item-value="id"
                                    :items="enviarCorreo"
                                    label="¿Enviar a Correo?"
                                    v-validate="'required'"
                                    required
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <!-- <v-btn color="primary" text @click="dialog3 = false">atras</v-btn> -->
                        <v-btn color="primary" text @click="closeFactura()">atras</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :loading="loadingFactura" type="submit">Continuar</v-btn>
                    </v-card-actions>
                </v-card>
            </form>
        </v-dialog>
        <v-dialog v-model="dialogError" max-width="400px">
            <v-card>
                <v-card-title class="grey lighten-3">
                    <h6>{{ labelDialog }}</h6>
                </v-card-title>
                <v-card-actions>
                    <v-btn color="primary" text @click="dialogError = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog4" max-width="400px">
            <v-card>
                <v-card-title class="grey lighten-3">
                    <h6>Escoga el formato deseado</h6>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-card class="d-flex justify-space-around mb-6" flat tile>
                                <v-card class="pa-3" outlined tile color="white">
                                    <v-btn color="blue" class="white--text" :loading="loadingFactura" @click="printDocument(1)">A4</v-btn>
                                </v-card>
                                <v-card class="pa-3" outlined tile color="white">
                                    <v-btn color="blue" class="white--text" type="submit" :loading="loadingFactura" @click="printDocument(2)">TICKET</v-btn>
                                </v-card>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="primary" text @click="closePrintFactura()">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="assignment" max-width="290">
            <v-card>
                <v-card-title class="text-h5"> </v-card-title>
                <v-card-text>
                    <h3>Asigne el Usuario de esta venta</h3>
                    <v-select clearable :items="listUsersAssign" v-model="userAssignment" item-text="nombres" item-value="id"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" text @click="assignment = false"> Cancelar </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :loading="loading2" @click="dialog5 = true"> Aceptar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog5" max-width="290">
            <v-card>
                <v-card-title class="text-h5"> Estas seguro? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :loading="loading2" @click="reAssignmentVenta()"> Aceptar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import { formatNumber, ticketPDF } from '@/utils/genericUtils'
import { mixins } from '@/mixins/mixin.js'
import { mapState, mapGetters } from 'vuex'
import { format, addDays } from 'date-fns'

export default {
    props: ['lista', 'title', 'head'],
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data: () => ({
        assignment: false,
        cuotas: [],
        plataformWeb: false,
        numberCuotas: 1,
        userAssignment: null,
        itemSelected: { id: null },
        loading2: false,
        idVenta: null,
        dialog2: false,
        dialog3: false,
        dialog4: false,
        dialog5: false,
        labelDialog: '',
        dialogError: false,
        search: '',
        linkPdf: '',
        optionDocument: 0,
        loadingFactura: false
    }),
    computed: {
        ...mapState('utilities', ['tipoMoneda', 'tipoComprobanteFact', 'tipoFormaEntrega', 'condicionPago', 'enviarCorreo']),
        ...mapState('users', ['user', 'listaUser']),
        ...mapState('ventas', ['loadingReservas', 'datos']),
        ...mapState('sedes', ['sedes', 'ubigeos']),
        ...mapGetters('users', ['optionalRole']),
        ...mapGetters('clientes', ['listaClientes']),
        isTipoComprobante() {
            return this.itemSelected.idTipoComprobante
        },
        listUsersAssign() {
            const items = []
            this.listaUser.map((x) => {
                if (x.id_role == 2 || x.id_role == 3) {
                    items.push({ ...x })
                }
            })
            return items
        },
        headers() {
            const idBussines = this.user ? this.user['idBussines'] : 1
            let lista = [
                { text: 'Actions', value: 'action', sortable: false, bussines: [1, 2, 4] },
                { text: 'ESTADO', value: 'descEstado', bussines: [1, 2, 4] },
                { text: 'TICKET', value: '_id', bussines: [1, 2, 4] },
                { text: 'SEDE', value: 'sedeDetail', bussines: [1, 2, 4] },
                { text: 'Plataforma', value: 'web_online', bussines: [4] },
                { text: 'Vendedor', value: 'UserAssignment', bussines: [1, 2, 4] },
                { text: 'Fecha Creación', value: 'fechaCreacion', bussines: [1, 2, 4] },
                { text: 'Hora Venta', value: 'horaCreated', bussines: [1, 2, 4] },
                { text: 'Fecha Entrega/Fin', value: 'check_in', bussines: [1, 2, 4] },
                { text: 'ITEMS', value: 'maxItems', bussines: [2, 4] },
                { text: 'Description', value: '_description', bussines: [1, 2, 4] },
                { text: 'Cliente', value: '_full_name', bussines: [1, 2, 4] },
                { text: 'Total a Pagar', value: 'importeTotal', filterable: false, bussines: [1, 2, 4] },
                {
                    text: 'Total Pagado (S/)',
                    value: 'importeAbonadoSoles',
                    filterable: false,
                    bussines: [1, 2, 4]
                },
                {
                    text: 'Total Pagado ($)',
                    value: 'importeAbonadoDolares',
                    filterable: false,
                    bussines: [2, 4]
                },
                { text: 'idCot / idOrdenCompra', value: 'cotOC', bussines: [2, 4] },
                { text: 'FormaEntrega', value: 'formaEntregaDesc', bussines: [2, 4] }
            ]
            if (this.head) {
                console.log('there is my headers', this.head)
                lista = this.head
            }
            const _lista = lista.filter((x) => x.bussines.includes(idBussines))
            //console.log('idBussines ', idBussines)
            console.log('lista', lista)
            return _lista
        },
        listUserSede() {
            const filterListUser = []
            this.listaUser.filter((x) => {
                if (x.idSede && x.isLogin == 1 && x.status == 1) {
                    filterListUser.push({ nombres: x.nombres, id: x.id })
                }
            })
            return filterListUser
        },
        _lista() {
            const items = []
            console.log('this.lista', this.lista)
            console.log('user--', this.user)
            if (this.lista.length > 0 && this.ubigeos) {
                this.lista.map((v) => {
                    const userAssignment = this.listaUser.find((x) => x.id == (v.idUserAssignment ? v.idUserAssignment : v.idColaborador))
                    let nameProduct = ''

                    const obtenerDescripcion = (elemento) => {
                        try {
                            const final = elemento && elemento.length > 0 ? (typeof elemento === 'string' ? JSON.parse(elemento)[0] : elemento[0]) : null
                            nameProduct = final ? final.description : ''
                        } catch (error) {
                            console.error('Error al procesar el elemento:', error)
                        }
                    }

                    const source = this.user.idBussines === 1 ? (v.privados.length > 0 ? v.privados : v.productos) : v.productos
                    obtenerDescripcion(source)
                    const fechaCompleta = new Date(v.created_at)
                    const horaFormateada = `${fechaCompleta.getUTCHours()}:${fechaCompleta.getUTCMinutes().toString().padStart(2, '0')}`
                    console.log('v.ubigeo ', v.ubigeo)
                    const _isProvincia = v.ubigeo ? v.ubigeo : null
                    console.log('_isProvincia ', _isProvincia)
                    console.log('this.ubigeos ', this.ubigeos)

                    const nombreDist = _isProvincia ? this.ubigeos.find((w) => w.id == _isProvincia).name : ''
                    console.log('nombreDist ', nombreDist)
                    let maxItems = 0
                    v.productos.forEach((el) => {
                        maxItems += parseInt(el.unidades, 10) || 0 // Asegura que sea un número y maneja NaN
                    })
                    const timePeriod = fechaCompleta.getUTCHours() >= 12 ? 'PM' : 'AM'
                    items.push({
                        ...v,
                        maxItems: maxItems,
                        importeTotal: formatNumber(v.importeTotal, v.idTipoMoneda),
                        efectivo: formatNumber(v.efectivo, v.idTipoMoneda),
                        tarjeta: formatNumber(v.tarjeta, v.idTipoMoneda),
                        transferencia: formatNumber(v.transferencia, v.idTipoMoneda),
                        adelanto: formatNumber(v.adelanto, v.idTipoMoneda),
                        cotOC: v.idCotizacion ? v.idCotizacion : v.idOrdenCompra,
                        oldTicket: `${v.oldTicket || ''} ${v.oldTicketReserva || ''}`,
                        _id: `T-${v.id}`,
                        _finalizado: v.finalizado == 1 ? 'F' : 'P',
                        estado: v.finalizado,
                        horaCreated: `${horaFormateada} ${timePeriod}`,
                        _full_name: v.full_name ? v.full_name.slice(0, 25) : '',
                        _description: nameProduct,
                        UserAssignment: userAssignment ? `${userAssignment.nombres} ${userAssignment.apellidos}` : 'FIORELLA CHECCLLO',
                        //formaEntregaDesc: v.formaEntrega ? this.tipoFormaEntrega.find((x) => x.id == v.formaEntrega)?.text : null,
                        formaEntregaDesc: v.formaEntrega ? `${this.tipoFormaEntrega.find((x) => x.id == v.formaEntrega)?.text} - ${nombreDist}` : null,

                        sedeDetail: this.sedes.find((x) => x.id == v.idSede)?.sede_name_id
                            ? this.sedes.find((x) => x.id == v.idSede)?.sede_name_id
                            : v.sedeDetail
                    })
                })
            }
            const userOptionalRole = JSON.parse(this.user.optionalRole)
            if (this.user.id_role == 4) {
                const filterLogistica = items.filter((x) => x.tipoEntrega != 9)
                return filterLogistica
            } else if (this.user.id_role == 6) {
                const filterVender = userOptionalRole.newVendor
                    ? items.filter((x) => x.idColaborador == this.user.id)
                    : items.filter((x) => x.idSede == this.user.idSede)
                return filterVender
            }
            return items
        }
    },
    methods: {
        deleteCuota(index) {
            this.cuotas.splice(index, 1)
        },
        async deleteItem() {
            this.loading2 = true
            await this.$store.dispatch('ventas/deleteVenta', this.itemSelected)
            await this.$store.dispatch('ventas/getReservas', {})
            this.loading2 = false
            this.dialog2 = false
        },
        closeFactura() {
            this.dialog3 = false
            this.cuotas = []
        },
        async closePrintFactura() {
            this.dialog4 = false
            await this.$store.dispatch('ventas/getReservas', {})
        },
        pushCuotas() {
            this.numberCuotas++
            const inCuotas = []
            this.cuotas.push({
                date: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
                importe: 0,
                menu: false
            })
            const importeTotal = Number(this.itemSelected.importeTotal.replace(/[^0-9.-]+/g, ''))
            //console.log('importeTotal', importeTotal)
            const cantidadDeudora = this.itemSelected.montoFacturacion || importeTotal
            //console.log('cantidadDeudora', cantidadDeudora)
            this.cuotas.map((x) => {
                inCuotas.push({ ...x, importe: (cantidadDeudora / this.numberCuotas).toFixed(2) })
            })
            this.cuotas = inCuotas

            //console.log('cuotas', this.cuotas)
        },
        colorEstado(payload) {
            let color
            switch (payload) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 12:
                    color = 'green'
                    break
                case 8:
                case 13:
                    color = 'orange'
                    break
                case 9:
                case 10:
                case 11:
                case 14:
                    color = 'red'
                    break
            }
            return color
        },
        printReservation(item) {
            //console.log(item)
            /* this.$store.commit("ventas/UPDATE_DATOS", item); */
            this.$store.dispatch('printer/reservation', item)
        },
        openDelete(item) {
            this.dialog2 = true
            this.itemSelected = item
        },
        async sendProof(data) {
            const documento = [
                { id: 1, fileName: data.data.data.filename, url: data.data.links.pdf },
                { id: 2, fileName: data.data.data.filename, url: `${data.data.links.pdf}/ticket` }
            ]
            if (this.itemSelected.isEmailSelected != 3) {
                await this.$store.dispatch('ventas/enviarComprobante', { ...this.itemSelected, documento })
            }
        },
        async activeTicket(item) {
            let n = 0
            while (n < 2) {
                this.$store.dispatch('ventas/activarTicket', item)
                await this.$store.dispatch('ventas/getReservas', {})
                n++
            }
        },
        async printDocument(index) {
            if (index === 1) {
                await window.open(this.linkPdf)
            } else {
                await window.open(`${this.linkPdf}/ticket`)
            }
        },
        async reAssignmentVenta() {
            this.loading2 = true
            await this.$store.dispatch('ventas/assignmentVenta', {
                idUsuario: this.userAssignment,
                idVenta: this.idVenta,
                data: this.listaUser.find((x) => x.id == this.userAssignment)
            })
            this.loading2 = false
            this.assignment = false
            this.dialog5 = false
            await this.$store.dispatch('ventas/getReservas', {})
        },
        openDialog3(item) {
            //console.log('item', item)
            if (item.numeroComprobante) {
                this.labelDialog = 'Ya existe un numero de comprobante registrado'
                this.dialogError = true
            } else {
                const cliente = this.listaClientes.find((x) => x.id == item.idCliente)
                this.numberCuotas = 1
                this.itemSelected = item
                this.itemSelected.idUserAssignment = item.idUserAssignment
                this.itemSelected.idTipoComprobante = cliente?.identity_document_type_id == '1' ? 2 : 1
                const importeTotal = Number(this.itemSelected.importeTotal.replace(/[^0-9.-]+/g, ''))
                const cantidadDeudora = this.itemSelected.montoFacturacion || importeTotal
                this.cuotas.push({
                    date: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
                    importe: cantidadDeudora,
                    menu: false
                })
                this.dialog3 = true
            }
        },
        async comprobante() {
            try {
                this.loadingFactura = true
                if (this.itemSelected.idTipoComprobante == 1 && this.itemSelected.numeroDocumento.split(' ').join('').length == 8) {
                    this.labelDialog = 'DNI NO PUEDE GENERAR FACTURA'
                    this.dialogError = true
                } else if (!this.itemSelected.full_name) {
                    this.labelDialog = 'Falta Seleccionar un Cliente'
                    this.dialogError = true
                } else if (!this.itemSelected.importeTotal && !this.itemSelected.montoFacturacion) {
                    this.labelDialog = 'No hay ningun costo a Pagar'
                    this.dialogError = true
                } else {
                    const cliente = this.listaClientes.find((x) => x.id == this.itemSelected.idCliente)
                    const findNewUser = this.listaUser.find((x) => x.id == this.itemSelected.idUserAssignment)
                    const { data } = await this.$store.dispatch('ventas/comprobante', {
                        ...this.itemSelected,
                        cliente,
                        cuotas: this.cuotas,
                        userNumeroDocumento: findNewUser.documento || this.user.documento,
                        idSede: findNewUser.idSede
                    })
                    if (data.codigoRespuesta == '99') {
                        this.$store.commit('mainUI/OPEN_MODAL', {
                            state: true,
                            close: true,
                            text: data.messageError
                        })
                    } else {
                        this.cuotas = []
                        if (data.codigoRespuesta == '0' || data.data.data.id) {
                            this.linkPdf = data.data.links['pdf']
                            await this.$store.dispatch('ventas/patchVenta', {
                                id: this.itemSelected.id,
                                atributos: { ...this.datos.atributos, url_pdf_doc: this.linkPdf }
                            })

                            this.dialog4 = true
                            await this.sendProof(data)
                        } else {
                            this.labelDialog = data.message
                            this.dialogError = true
                        }
                    }
                }
                this.loadingFactura = false
                this.dialog3 = false
            } catch (error) {
                console.log('error_comprobante', error)
                this.$store.commit('mainUI/OPEN_MODAL', {
                    state: true,
                    text: error
                })
            }
        },
        async editItem(payload) {
            await Promise.all([this.$store.dispatch('ventas/selectReserva', payload)])
            this.$store.commit('ventas/SHOW_MODAL', { step: 2, state: true })
        },
        async reAssignment(payload) {
            this.assignment = true
            this.idVenta = payload.id
            this.userAssignment = payload.idUserAssignment
        },
        async showItem(payload) {
            await this.$store.dispatch('ventas/selectReserva', payload)
            this.$store.commit('ventas/SHOW_MODAL', {
                step: 2,
                state: true,
                showItem: true
            })
        },
        async createOrden(payload) {
            //console.log('payload ', payload)
            const pathURL = await this.$store.dispatch('reporte/reporteOrdenPedido', payload)
            //console.log('pathURL ', pathURL)
            this.pathURL = pathURL
            //this.$store.commit('ventas/SHOW_MODAL', { step: 2, state: true, showItem: true })
        }
    },
    watch: {
        async isTipoComprobante(val) {
            const importeTotal = Number(this.itemSelected.importeTotal.replace(/[^0-9.-]+/g, ''))
            const cantidadDeudora = this.itemSelected.montoFacturacion || importeTotal
            this.cuotas = [{ date: format(addDays(new Date(), 30), 'yyyy-MM-dd'), importe: cantidadDeudora, menu: false }]
        }
    },
    async mounted() {
        await this.$store.dispatch('sedes/getAllSedes')
    }
}
</script>
